var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"item-box"},[(_vm.chartData)?_c('btc-chart',{key:_vm.data.value,ref:"chart",attrs:{"data":_vm.chartData,"unit":_vm.unit,"is-en-expire":_vm.isEnExpire,"title":_vm.data.label},nativeOn:{"click":function($event){return _vm.handleCharts.apply(null, arguments)}}}):_vm._e()],1),_c('div',{staticClass:"item-box",staticStyle:{"padding":"30px"}},[_c('div',{staticClass:"selector-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.value==='中国香港'),expression:"data.value==='中国香港'"}],staticClass:"selector-left"},[_c('selector',{key:_vm.data.value+'1',attrs:{"items":_vm.type},on:{"tab-item":_vm.handleTabType}}),_c('selector',{key:_vm.data.value+'2',attrs:{"items":_vm.meatType,"disabled":_vm.isDisable},on:{"tab-item":_vm.handleTabMeatType}}),_c('selector',{key:_vm.data.value+'3',attrs:{"items":_vm.storage,"disabled":_vm.isDisable},on:{"tab-item":_vm.handleTabStorage}})],1),(_vm.startDate&&_vm.endDate)?_c('datetime',{key:_vm.data.value,attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event},"tap-start":function($event){return _vm.handleTapDate($event, 'StartDate')},"tap-end":function($event){return _vm.handleTapDate($event, 'EndDate')}}}):_vm._e()],1),_c('btc-table-header',{attrs:{"data":_vm.data.value==='中国香港' ? _vm.header : _vm.header2}}),_c('btc-table',{attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('btc-table-cell',{attrs:{"data":row,"index":index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
var index = ref.index;
return [(cell.Destination==='总计')?_c('span',[_vm._v("*")]):_c('span',[_vm._v(_vm._s(index))])]}}],null,true)}),_c('btc-table-cell',{attrs:{"data":row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Destination))])]}}],null,true)}),_c('btc-table-cell',{attrs:{"data":row},nativeOn:{"click":function($event){return _vm.isLoginEnExpire.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('span',[_vm._v(_vm._s(cell.Volume))])]}}],null,true)}),_c('btc-table-cell',{attrs:{"data":row},nativeOn:{"click":function($event){return _vm.isLoginEnExpire.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cell = ref.cell;
return [_c('b-form-checkbox',{attrs:{"value":cell.Destination,"disabled":(_vm.checkbox.length === 1 && _vm.checkbox[0] === cell.Destination) || !_vm.isEnExpire || _vm.isLogin,"plain":""},on:{"change":_vm.handleChangeCheckbox},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }